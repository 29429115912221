@media (min-width: 1080px) {
  .menuItems {
    display: flex;
    flex-direction: column;
  }
  .menuBar {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    /* object-fit: contain; */
    height: 100%;
    max-width: 400px;
    width: 100%;
    justify-content: center;
    z-index: 1;
  }
  .menuBarcolor-light {
    background-color: white;
  }
  .menuBarcolor-dark {
    background-color: #181818;
  }
  .mainItem {
    font-size: xx-large;
    font-weight: 900;
    letter-spacing: 2px;

    text-align: start;
    margin-left: 100px;
    /* padding: 10px 0; */
  }
  .otherItem {
    text-align: start;
    margin-left: 100px;
  }
  .menupadding {
    padding: 12px;
  }
  .pointer {
    cursor: pointer;
  }
  .links {
    text-decoration: none;
    cursor: pointer;
    transition: letter-spacing 0.3s;
  }

  .links:hover {
    letter-spacing: 2px;
  }
  .mainlinkcolor-light
  {
    color: black;
  }
  .mainlinkcolor-dark
  {
    color: #FD2155;
    text-shadow: -3px 0 #08fdd8;
  }
  .linkcolor-light {
    color: inherit;
  }
  .linkcolor-dark {
    color: #909096;
  }
  .currentlink-light {
    cursor: pointer;
    color: black;
    opacity: 0.5;
    text-decoration: none;
  }
  .currentlink-dark {
    cursor: pointer;
    color: #08fdd8;
    text-decoration: none;
  }
  .darkmodeicon-dark
  {
    color: #FD2155;
    cursor: pointer;

  }
  .darkmodeicon-light
  {
    color: inherit;
    cursor: pointer;

  }
  .entry {
    animation: slideFromRight 0.6s;
    animation-delay: 0ms;
  }

  @keyframes slideFromRight {
    0% {
      transform: translateX(30%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
/************************************************************/

@media (max-width: 1080px) {
  .menuBar {
    position: fixed;
    top: 0;
    right: 0;
    /* object-fit: contain; */
    height: fit-content;
    max-width: 100vw;
    width: 100vw;
    z-index: 1;
  }
  .menuBarcolor-light {
    background-color: white;
  }
  .menuBarcolor-dark {
    background-color: #181818;
  }
  .menuItems {
    display: flex;
    flex-direction: row;
    margin: 10px;
  }
  .mainItem {
    font-size: large;
    font-weight: 900;
    letter-spacing: 2px;
    text-align: start;
    flex: 1;
    float: inline-end;
    /* padding: 10px 0; */
  }
  .otherItem {
    text-align: end;
    flex: 0;
    margin-left: 10px;
  }
  .menuOtherItemText {
    display: none;
  }
  .menupadding {
    padding: 5px;
  }
  .links {
    cursor: pointer;
  }
  .mainlinkcolor-light
  {
    color: black;
  }
  .mainlinkcolor-dark
  {
    color: white;
  }
  .linkcolor-light {
    color: inherit;
  }
  .linkcolor-dark {
    color: #909096;
  }
  .currentlink-light {
    cursor: pointer;
    color: black;
    opacity: 0.5;
    text-decoration: none;
  }
  .currentlink-dark {
    cursor: pointer;
    color: #08fdd8;
    text-decoration: none;
  }
  .darkmodeicon-dark
  {
    color: #FD2155;
    cursor: pointer;

  }
  .darkmodeicon-light
  {
    color: inherit;
    cursor: pointer;

  }
  
}

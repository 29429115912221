@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');
@media (min-width: 1080px) {
  .skillsmargin {
    margin-left: 400px;
  }
  .paddingleft {
    padding-left: 120px;
  }
  .skillpage {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh; */
    margin-top: 120px;
    animation: slideInFromLeft 0.6s;

    /* make text non-selectable */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
  }
  .skillcontent {
    display: flex;
    flex-direction: row;
  }
  .heading {
    font-size: 48px;
    font-weight: 900;
  }
  .skillsandexp-light {
    padding-bottom: 20px;

  }
  .skillsandexp-dark {
    padding-bottom: 20px;
    color: #08fdd8;
  }
  .skillsandexp-light::before {
    content: '<h2>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-light::after {
    content: '</h2>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-dark::before {
    content: '<h2>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-dark::after {
    content: '</h2>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .paragraph-light {
    font-style: italic;
    color: #515152;
    padding: 20px auto;
    max-width: 35vw;
  }
  .paragraph-dark {
    font-family: 'Ubuntu';
    color: #8d8d8d;
    padding: 20px auto;
    max-width: 35vw;
  }
  .paragraph-light::before {
    content: '<p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph-light::after {
    content: '</p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    display: block;
    margin-bottom: -1rem;
    margin-left: -1rem;
  }
  .paragraph-dark::before {
    content: '<p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph-dark::after {
    content: '</p>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-bottom: -1rem;
    margin-left: -1rem;
  }
  .skills-left-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skills-right-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-right: 10px; */
  }
  .soft-skills {
    margin: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;
  }
  .soft-skills-font-light {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
  }
  .soft-skills-font-dark {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
    color: #8d8d8d;
  }
  .soft-skills-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5vw;
    height: 5vw;
  }
  .skills-icon-color-light
  {
    color: black;
  }
  .skills-icon-color-dark
  {
    color:#08fdd8;
  }
  .workex-light {
    margin: 20px;
    
    padding: 20px;
    background-color: lightgray;
    max-width: 20vw;
  }
  .workex-dark {
    margin: 20px;
    
    padding: 20px;
    background-color: #2a2a2a;
    max-width: 20vw;
  }
  .bottomtag {
    bottom: 2px;
    margin-left: 400px;
    position: absolute;
  }
}

@media (max-width: 1080px) and (min-width: 768px) {
  .skillsmargin {
    margin-left: 0px;
  }
  .paddingleft {
    padding-left: 120px;
  }
  .skillpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    animation: slideInFromLeft 0.6s;

    /* make text non-selectable */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
  }
  .skillcontent {
    display: flex;
    flex-direction: row;
  }
  .heading {
    font-size: 48px;
    font-weight: 900;
  }
  .skillsandexp-light {
    padding-bottom: 20px;
  }
  .skillsandexp-dark{
    padding-bottom: 20px;
    color: #08fdd8;
  }
  .skillsandexp-light::before {
    content: '<h2>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-light::after {
    content: '</h2>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-dark::before {
    content: '<h2>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp-dark::after {
    content: '</h2>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .paragraph-light {
    font-style: italic;
    color: gray;
    padding: 20px auto;
    max-width: 45vw;
  }
  .paragraph-dark {
    font-family: 'Ubuntu';
    color: #8d8d8d;
    padding: 20px auto;
    max-width: 45vw;
  }
  .paragraph-light::before {
    content: '<p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph-light::after {
    content: '</p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph-dark::before {
    content: '<p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph-dark::after {
    content: '</p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: #515152;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .skills-left-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skills-right-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .soft-skills {
    margin: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;
  }
  .soft-skills-font-light {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
  }
  .soft-skills-font-dark {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
    color: #8d8d8d;
  }
  .soft-skills-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5vw;
    height: 5vw;
  }
  .skills-icon-color-light
  {
    color: black;
  }
  .skills-icon-color-dark
  {
    color:#08fdd8;
  }
  .workex-light {
    margin: 20px 20px 0px 20px;
    width: 100%;
    padding: 20px;
    background-color: lightgray;
    max-width: 20vw;
  }
  .workex-dark {
    margin: 20px 20px 0px 20px;
    width: 100%;
    padding: 20px;
    background-color: #2a2a2a;
    max-width: 20vw;
  }
  .bottomtag {
    bottom: 2px;
    position: absolute;
  }
}
/*8888*************************/
@media (max-width: 768px) {
  .skillsmargin {
    margin-left: 0px;
  }
  .paddingleft {
    padding-left: 7vw;
  }

  .skillpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    animation: slideInFromLeft 0.6s;

    /* make text non-selectable */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
  }
  .skillcontent {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    margin: auto;
  }
  .heading {
    font-size: 48px;
    font-weight: 900;
  }
  .skillsandexp-light {
    padding-bottom: 20px;
  }
  .skillsandexp-dark {
    padding-bottom: 20px;
    color: #08fdd8;
  }
  /* .skillsandexp::before {
    content: '<h2>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .skillsandexp::after {
    content: '</h2>';
    display: block;
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: -1rem;
  } */
  .paragraph-light {
    font-style: italic;
    color: gray;
    padding: 20px auto;
    max-width: 100%;
  }
  .paragraph-dark {
    font-style: italic;
    color: lightgray;
    padding: 20px auto;
    max-width: 100%;
  }
  
  /* .paragraph::before {
    content: '<p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  }
  .paragraph::after {
    content: '</p>';
    font-family: 'Fasthand', Helvetica, sans-serif;
    color: lightgray;
    font-size: 18px;
    font-weight: 100;
    font-style: italic;
    opacity: 1;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: -1rem;
  } */
  .skills-left-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skills-right-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
  }
  .soft-skills {
    margin: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 50px;
    row-gap: 50px;
  }
  .soft-skills-font-light {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
  }
  .soft-skills-font-dark {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    font-weight: 400;
    color: #8d8d8d;
  }
  .soft-skills-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5vw;
    height: 5vw;
  }
  .skills-icon-color-light
  {
    color: black;
  }
  .skills-icon-color-dark
  {
    color:#08fdd8;
  }
  .workex-light {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: lightgray;
    width: 100%;
    max-width: 75vw;
  }
  .workex-dark {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #2a2a2a;
    width: 100%;
    max-width: 75vw;
  }
  /* .bottomtag {
    bottom: 2px;
    display: block;
  } */
}

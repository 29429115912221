@media (min-width: 1320px) {
  .animate {
    animation: entryanim 0.6s;
  }
  .projectbackground-light {
    background-color: inherit;
  }
  .projectbackground-dark {
    background-color: #1d1d1d;
  }
  .grid-container {
    margin-left: 400px;
    padding-left: 120px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    row-gap: 60px;
    margin-bottom: 50px;
  }
  @keyframes entryanim {
    0% {
      transform: translateX(-30%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .projecttoptext {
    margin-left: 400px;
    padding-top: 100px;
    padding-left: 120px;
  }
  .projectheading-light
  {
    background-color: #eeeeee;
    padding: 10px;
  }
  .projectheading-dark
  {
    font-weight: 900;
    font-size: 55px;
    color: #08fdd8;
  }
  .personal-project-heading-light {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 22pt;
  }
  .personal-project-heading-dark {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 22pt;
    color: white;
    
  }
}
@media (max-width: 1320px) and (min-width: 1080px) {
  .animate {
    animation: entryanim 0.6s;
  }
  .projectbackground-light {
    background-color: inherit;
  }
  .projectbackground-dark {
    background-color: #1d1d1d;
  }
  .grid-container {
    margin: 50px auto;
    padding-left: 500px;
    /* margin-top: 50px; */
    display: grid;
    grid-template-columns: auto;
    /* grid-template-rows: auto; */
    row-gap: 60px;
    margin-bottom: 50px;
  }
  @keyframes entryanim {
    0% {
      transform: translateX(-30%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .projecttoptext {
    margin-left: 400px;
    padding-top: 200px;
    padding-left: 120px;
  }
  .projectheading-light
  {
    background-color: #eeeeee;
    padding: 10px;
  }
  .projectheading-dark
  {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 900;
    font-size: 50px;
    color: #08fdd8;
  }
  .personal-project-heading-light {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 22pt;
  }
  .personal-project-heading-dark {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 22pt;
    color: white;
    
  }
}

@media (max-width: 1080px) {
  .animate {
    animation: entryanim 0.6s;
  }
  .projectbackground-light {
    background-color: inherit;
  }
  .projectbackground-dark {
    background-color: #1d1d1d;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    row-gap: 60px;
    margin-bottom: 50px;
  }
  .projectheading-light
  {
    background-color: #eeeeee;
    padding: 10px;
  }
  .projectheading-dark
  {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 900;
    font-size: 40px;
    color: #08fdd8;
  }
  .personal-project-heading-light {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 30px;
  }
  .personal-project-heading-dark {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 22pt;
    color: white;
    
  }
  @keyframes entryanim {
    0% {
      transform: translateX(-30%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .projecttoptext {
    margin-top: 100px;
    padding-left: 10vw;
  }
}

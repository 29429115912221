@media (min-width: 768px) {
  .artgallery {
    margin: 70px 0px;
    width: fit-content;
    min-width: 40vw;
    padding-right: 20px;
  }
  .galler-images {
    display: grid;
    flex-direction: row;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
  }
  .frame-light {
    height: 30vh;
    width: fit-content;
    border: solid 1px black;
    margin: 20px 10px;
    transition: transform 0.2s;
  }
  .frame-dark {
    height: 30vh;
    width: fit-content;
    border: solid 1px black;
    margin: 20px 10px;
    transition: transform 0.2s;
  }
  .frame-light:hover {
    border: solid 1px black;
    transform: scale(1.1);
  }
  .frame-dark:hover {
    transform: scale(1.1);
    border: solid 1px #08fdd8;
  }
  .gallery-topbar {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .artgallery-font-light {
    flex: 1;
    font-size: 18px;
    font-weight: 700;
  }
  .artgallery-font-dark {
    color: #08fdd8;
    flex: 1;
    font-size: 18px;
    font-weight: 700;
  }
  .viewall-light {
    flex: 0;
    padding: 5px 10px;
    background-color: lightgray;
    color: black;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.2s;
  }
  .viewall-dark {
    color: white;
    flex: 0;
    padding: 5px 10px;
    cursor: pointer;
    border: solid 1px #08fdd8;
    text-decoration: none;
    transition: background-color 0.2s;
  }
  .viewall-dark:hover {
    color: black;
    background-color: #08fdd8;
    text-decoration: none;
  }
  .viewall-light:hover {
    background-color: gray;
    text-decoration: none;
  }
}

/****************************************************************************/
@media (max-width: 768px) {
  .artgallery {
    margin: 70px 0px;
    width: fit-content;
    padding-right: 10vw;
  }
  .galler-images {
    display: grid;
    flex-direction: row;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
  }
  .frame-light {
    height: 40vw;
    border: solid 1px black;
    margin: 20px 10px;
    transition: transform 0.2s;
  }
  .frame-dark {
    height: 40vw;
   
    border: solid 1px black;
    margin: 20px 10px;
    transition: transform 0.2s;
  }
  .frame-light:hover {
    border: solid 1px black;
    transform: scale(1.1);
  }
  .frame-dark:hover {
    transform: scale(1.1);
    border: solid 1px #08fdd8;
  }
  .gallery-topbar {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .artgallery-font-light {
    flex: 1;
    font-size: 18px;
    font-weight: 700;
  }
  .artgallery-font-dark {
    color: #08fdd8;
    flex: 1;
    font-size: 18px;
    font-weight: 700;
  }
  .viewall-light {
    flex: 0;
    padding: 5px 10px;
    background-color: lightgray;
    cursor: pointer;
  }
  .viewall-dark {
    color: white;
    flex: 0;
    padding: 5px 10px;
    cursor: pointer;
    border: solid 1px #08fdd8;
  }
  .viewall-dark:hover {
    color: #08fdd8;
  }
  .viewall-light:hover {
    background-color: gray;
  }
}
